import gabiludImg from './gabilud.jpg';
import gabilud2Img from './gabilud2.jpg';
import passargada1Img from './passargada1.jpg';
import passargada2Img from './passargada2.jpg';
import passargada3Img from './passargada3.jpg';
import passargada4Img from './passargada4.jpg';
import passargada5Img from './passargada5.jpg';

import gabrielPix from './pix/gabriel.png';
import lurdesPix from './pix/lurdes.png';
import logo from './logo.png';

import djYouNique from './djs/youNique.jpeg';
import djMaluf from './djs/maluf.jpeg';
import djRodrigoCosta from './djs/djRodrigoCosta.jpeg';
// import tree from './tree.png';
// import tree2 from './tree2.png';
// import tree2Flip from './tree2-flip.png';


export const gabiludImgData = {
    img: gabiludImg,
    width: 5184,
    height: 3456,
};

export const gabiludImage2Data = {
    img: gabilud2Img
};

export const gabrielPixData = {
    img: gabrielPix,
    width: 500,
    height: 500,
};

export const lurdesPixData = {
    img: lurdesPix,
    width: 500,
    height: 500,
};

export const logoData = {
    img: logo,
    width: 600,
    height: 600,
};

export const passargada1Data = {img: passargada1Img};
export const passargada2Data = {img: passargada2Img};
export const passargada3Data = {img: passargada3Img};
export const passargada4Data = {img: passargada4Img};
export const passargada5Data = {img: passargada5Img};

export const djYouNiqueData = {img: djYouNique, name: 'Younique', link: 'https://instagram.com/youniquedj'};
export const djMalufData = {img: djMaluf, name: 'Maluf', link: 'https://instagram.com/maluffmusic'};
export const djRodrigoCostaData = {img: djRodrigoCosta, name: 'Dj Rodrigo Costa', link: 'https://instagram.com/djrodriigocosta'};

export const djList = [
    djYouNiqueData,
    djMalufData,
    djRodrigoCostaData,
];

// export const treeData = {
//     img: tree,
// }

// export const tree2Data = {
//     img: tree2,
// }

// export const tree2FlipData = {
//     img: tree2Flip,
// }