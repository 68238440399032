import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { gabrielPixData, lurdesPixData } from '../assets';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(400, 80vw)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const gabrielPixModalData = {
  title: 'PIX Gabriel',
  img: gabrielPixData.img,
  chave: "gabrielmelo1992@gmail.com"
};

export function PixGabrielButton({onClick}) {

  const _onClick = () => onClick({...gabrielPixModalData});

  return (
    <Button fullWidth variant="contained" onClick={_onClick}>
      {gabrielPixModalData.title}
    </Button>
  );
};

const ludmylaPixModalData = {
  title: 'PIX Ludmyla',
  img: lurdesPixData.img,
  chave: "lud_la@hotmail.com"
};

export function PixLudmylaButton({onClick}) {

  const _onClick = () => onClick({...ludmylaPixModalData});

  return (
    <Button fullWidth variant="contained" onClick={_onClick}>
      {ludmylaPixModalData.title}
    </Button>
  );
};

export default function PixModal({open, data, handleClose}) {

  const copyKeyToClipboard = () => {
    navigator.clipboard.writeText(data?.chave ?? "");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" pb={4}>
            {data?.title ?? ""}
          </Typography>
          <img src={data?.img ?? ""} alt={data?.title ?? ""} style={{width: "100%", height: 'auto'}}/>
          <Box display="flex" alignItems="center" mt={3} gap={2} sx={{cursor: 'pointer'}} onClick={copyKeyToClipboard}>
            <Typography variant="body2">
              Chave: {data?.chave ?? ""}
            </Typography>
            <ContentCopyIcon />
          </Box>
          <Typography variant="body2" mt={2}>
            Deixem uma messagem para nós!
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}