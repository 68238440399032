export const color = {
    // "primary": "#845EC2",
    // "secondary": "#C493FF",
    // "tertiary": "#FEFEDF",
    // "quaternary": "#00C9A7",
    "primary": "#635281",
    "secondary": "#C493FF",
    "tertiary": "#FEFEDF",
    "quaternary": "#9dacc6",
};

export const gradient = {
    // "primary": `radial-gradient(black, black, ${color.primary})`,
    // "primary": `linear-gradient(90deg, ${color.primary}, ${color.quaternary}, ${color.primary})`,
    "primary70": `linear-gradient(90deg, ${color.primary}70, ${color.quaternary}70, ${color.primary}70)`,
};