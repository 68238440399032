import React, {Fragment, useState, useRef, useEffect, forwardRef} from 'react';


import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Carousel from 'react-material-ui-carousel'
import { makeStyles, styled } from '@mui/styles';
import Fade from '@mui/material/Fade';

import AppBar from '../../components/AppBar';
import Background from '../../components/Background';
import DjAvatar from '../../components/DjAvatar';

import PixModal, {PixGabrielButton, PixLudmylaButton} from '../../components/PixModal';
import GetWindowDimensions from '../../utils/Hooks/GetWindowDimensions';
import GetScrollPosition from '../../utils/Hooks/GetScrollPosition';

import texts from './texts';

import {
  gabiludImgData,
  passargada1Data,
  passargada2Data,
  passargada3Data,
  passargada4Data,
  passargada5Data,
  djList,
} from '../../assets';


const PADDING_TOP = 60;
const APPBAR_HEIGHT = 60;


const mainBoxSx = {
  position: 'relative',
  // overflowX: 'hidden',
};

const homeBoxWrapperSx = {
  position: 'relative',
  width: '100vw',
  height: '100%',
  maxWidth: '100%',
};

const mainBoxTypographySx = {
  position: 'absolute',
  top: 120,
  right: '5%',
  // color: 'white',
  // color: color.primary,
  // color: 'white',
  // textShadow: `1px 1px 3px black`,
};

const DEFAULT_SHADOW = "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)";

const appBoxSx = {
  position: 'absolute',
  display: 'flex',
  top: PADDING_TOP,
  flexDirection: 'column',
  alignItems: 'center',
  // overflowY: 'scroll',
  overflowX: 'hidden',
  // height: 'calc(100vh - 64px)',
  width: '100%',
  zIndex: 10,
}

const StyledBox = styled(Box)({
  height: "100vh",
  color: "black",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const observerOptions = {
  rootMargin: '0px 0px 0px 0px',
  threshold: 0.5
};

const ContentBox = forwardRef((props, ref) => {

  const [isVisible, setVisible] = React.useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    }, observerOptions);
    observer.observe(ref.current);
    // return () => observer.unobserve(boxRef.current);
    // eslint-disable-next-line
  }, []);

  return (
    <Fade in={isVisible} timeout={500}>
      <StyledBox ref={ref} {...props} />
    </Fade>
  )
});

const useStyles = ({width}) => makeStyles({
  carousel: {
    height : `600px`,
    width : `${width}px`,
    // marginBottom: -60
  }
});

export default function Home({width}) {

  const [nightScale, setNightScale] = useState(0);
  const [pixModalData, setPixModalData] = useState(null);
  const pixModalOpen = Boolean(pixModalData);
  const onPixModalClose = () => setPixModalData(null);

  const scrollPosition = GetScrollPosition();

  const classes = useStyles({width: width-400})();

  const {
    // width: windowWidth,
    height: windowHeight
  } = GetWindowDimensions();

  const mainBoxRef = useRef();
  const homeBoxRef = useRef();
  const welcomeBoxRef = useRef();
  const scenarioBoxRef = useRef();
  const howToGoBoxRef = useRef();
  const confirmPresenceRef = useRef();
  const presentsRef = useRef();
  const oQueVestirRef = useRef();
  const thePartyRef = useRef();

  const pageHeight = mainBoxRef?.current?.clientHeight ?? 0;

  useEffect(() => {

    let scrollTotalHeight = pageHeight + PADDING_TOP - windowHeight;
    let scrollPercentage = parseFloat((Math.max(0, Math.min(1, scrollPosition / scrollTotalHeight))).toFixed(2));
    let startNightEffectAt = 0.9;
    let nightScale = 0;
    if (scrollPercentage > startNightEffectAt) {
      nightScale = (scrollPercentage - startNightEffectAt) * (1/(1 - startNightEffectAt));
    };
    setNightScale(isNaN(nightScale) ? 0 : nightScale);

  }, [windowHeight, pageHeight, scrollPosition] );

  const appbarButtonList = [
    {type: "logo",   onClick: () => scrollToPosition(0)},
    {type: "button", label: 'Bem-Vindos', onClick: () => scrollToElement(welcomeBoxRef.current)},
    {type: "button", label: 'O cenário', onClick: () => scrollToElement(scenarioBoxRef.current)},
    {type: "button", label: 'Como chegar', onClick: () => scrollToElement(howToGoBoxRef.current)},
    {type: "button", label: 'Confirmar Presença', onClick: () => scrollToElement(confirmPresenceRef.current)},
    {type: "button", label: 'Presentes', onClick: () => scrollToElement(presentsRef.current)},
    {type: "button", label: 'O Que Vestir', onClick: () => scrollToElement(oQueVestirRef.current)},
    {type: "button", label: 'A Festa', onClick: () => scrollToElement(thePartyRef.current)},
  ];

  const scrollToPosition = (top) => {
    window.scrollTo({left: 0, top, behavior: 'smooth'});
  };

  const scrollToElement = (element) => {
    let position = element.getBoundingClientRect();
    scrollToPosition(position.top + scrollPosition);
  };

  const passargadaCarousol = [
    {src: passargada1Data.img, style: {width: width - 400, borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada2Data.img, style: {width: width - 400, borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada3Data.img, style: {width: width - 400, borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada4Data.img, style: {width: width - 400, borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada5Data.img, style: {width: width - 400, borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
  ];

  const onClickPixButton = (data) => setPixModalData(data);

  return (
    <Box id="main-box" sx={mainBoxSx}>

      <AppBar buttonList={appbarButtonList}/>

      <Box ref={mainBoxRef} id="app-box" sx={appBoxSx}>

        <ContentBox ref={homeBoxRef} id="home">

          <Box id="home-box-wrapper" sx={homeBoxWrapperSx}>
            <img
              alt=""
              src={gabiludImgData.img}
              style={{
                width: "100%", borderRadius: '4px',
                // boxShadow: DEFAULT_SHADOW,
                height: `calc(100vh - ${PADDING_TOP}px)`,
                objectFit: 'cover',
                objectPosition: '50% 20%',
              }}
            />
            <Box sx={mainBoxTypographySx}>
              <Typography
                fontSize={70}
                align='center'
              >
                Gabriel e Ludmyla
              </Typography>
              <Typography fontSize={50} align='center'>
                28.10.2023
              </Typography>
            </Box>
          </Box>

        </ContentBox>

        <ContentBox ref={welcomeBoxRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.bemVindo.title()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={3}>
            {texts.bemVindo.text1()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={3}>
            {texts.bemVindo.text2()}
          </Typography>
        </ContentBox>

        <ContentBox ref={scenarioBoxRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.cenario.title()}
          </Typography>
          <Box display="flex" flexDirection="column" width="100%" gap={3}>
            <Box display="flex" justifyContent="center">
              <Typography align='justify' variant="h5">
                {texts.cenario.text1()}
              </Typography>
            </Box>
            <Box display="flex" width="100%" justifyContent={"center"}>
              <Carousel autoPlay className={classes.carousel}>
                {passargadaCarousol.map((imageData, index) => 
                  <img key={index} alt="" src={imageData.src} style={imageData.style}/>
                )}
              </Carousel>
            </Box>
          </Box>
        </ContentBox>

        <ContentBox ref={howToGoBoxRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.comoChegar.title()}
          </Typography>

          <Typography variant="h5" marginBottom={2}>
            {texts.comoChegar.text1()}
          </Typography>

          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.comoChegar.text2()}
          </Typography>

          <Box display="flex" width="100%" gap={3}>
            <iframe
              width="540"
              height="412"
              src={"https://www.youtube.com/embed/LFTwNbx3ah4"}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />

            <iframe
              title="endereco-passargada"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1713.4947143635438!2d-38.08718212075416!3d-12.644166429786509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x716518dec4da065%3A0x8c04c329f0e4c604!2sSit%C3%ADo%20Pass%C3%A1rgada%20Guarajuba-%20Evento!5e0!3m2!1sen!2sbr!4v1683383026137!5m2!1sen!2sbr"
              width="540" height="412" style={{border: 0}} allowFullScreen="" loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </Box>
        </ContentBox>

        <ContentBox ref={confirmPresenceRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.confirmarPresenca.title()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={3}>
            {texts.confirmarPresenca.text1()}
          </Typography>
        </ContentBox>

        <ContentBox ref={presentsRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.presentes.title()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={5}>
            {texts.presentes.text1()}
          </Typography>
          <Box display="flex" width="100%" justifyContent={"center"} gap={3} marginBottom={5} flexDirection="column">
            <Button
              variant="contained"
              rel="noopener noreferrer" target="_blank" href="https://sites.icasei.com.br/gabilud/home"
            >
              LISTA DE PRESENTES
            </Button>
            <Box display="flex" gap={3}>
              <PixGabrielButton onClick={onClickPixButton} />
              <PixLudmylaButton onClick={onClickPixButton} />
            </Box>
          </Box>
          <Typography align='justify' variant="h5">
            {texts.presentes.text2()}
          </Typography>
        </ContentBox>

        <ContentBox height="110vh" ref={oQueVestirRef} width={width}>
          <Typography variant="h4" align='center' marginBottom={5}>
            {texts.oQueVestir.title()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={2}>
            {texts.oQueVestir.text1()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={2}>
            {texts.oQueVestir.text2()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={2}>
            {texts.oQueVestir.text3()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={2}>
            {texts.oQueVestir.text4()}
          </Typography>
          <Typography align='justify' variant="h5" marginBottom={2}>
            {texts.oQueVestir.text5()}
          </Typography>
          <Typography variant="h5">
            {texts.oQueVestir.text6()}
          </Typography>
        </ContentBox>

        <ContentBox ref={thePartyRef} width={width}>
          <Typography variant="h4" color="white" pb={8}>
            {texts.aFesta.title()}
          </Typography>

          <Box display={"flex"} justifyContent={"center"} flexWrap={"wrap"} gap={10}>
            {djList.map((djData, index) => (
              <DjAvatar key={index} img={djData.img} name={djData.name} link={djData.link}/>
            ))}
          </Box>

        </ContentBox>
      </Box>

      <Background nightScale={nightScale} appBarHeight={APPBAR_HEIGHT}/>
      <PixModal open={pixModalOpen} data={pixModalData} handleClose={onPixModalClose}/>
    </Box>
  );
};