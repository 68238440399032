import React, {Fragment} from 'react';


import GetWindowDimensions from '../../utils/Hooks/GetWindowDimensions';


import Desktop from './Desktop'
import Mobile from './Mobile'


export default function Home() {

  const {
    width: windowWidth,
    // height: windowHeight
  } = GetWindowDimensions();

  const width = Math.min(1100, windowWidth);

  return (
    <Fragment>
      {
        windowWidth >= 1200
        ? (
          <Desktop width={width}/>
        )
        : (
          <Mobile width={width}/>
        )
      }
    </Fragment>
  );
};