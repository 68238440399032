import React from 'react'


import Box from '@mui/material/Box';
// import Slide from '@mui/material/Slide';

// import { tree2Data, tree2FlipData } from '../assets';
import {color} from '../color';
import lightenOrDarkenHexColor from '../utils/functions/lightenOrDarkenHexColor';

const backgroundSx = {
  // display: 'flex',
  position: 'fixed',
  top: 0,
  height: '100vh',
  width: '100vw',
  zIndex: 0,
};


export default function Background({
  nightScale,
  heightOffset=10, widthOffset=0, appBarHeight=0, nightScaleThresholdList=[0.9, 0.94, 0.98],
  animationOff
}) {

  const newBackgroundSx = Object.assign({}, backgroundSx, {
    background: lightenOrDarkenHexColor(color.primary, (1 - nightScale) * 355 - 100)
  });

  // const showTreesBg3 = nightScale >= nightScaleThresholdList[2];
  // const showTreesBg2 = nightScale >= nightScaleThresholdList[1];
  // const showTreesBg1 = nightScale >= nightScaleThresholdList[0];

  // const imageList = [
  //   {slide: {direction: 'right', in: showTreesBg3}, img: {src: tree2Data.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     left: `${20 + parseInt(widthOffset*0.8)}vw`,
  //     opacity: 0.15,
  //     height: `calc(${100 + heightOffset}vh - ${appBarHeight}px)`
  //   }}},
  //   {slide: {direction: 'right', in: showTreesBg2}, img: {src: tree2Data.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     left: `${10 + parseInt(widthOffset*0.9)}vw`,
  //     opacity: 0.3,
  //     height: `calc(${120 + heightOffset}vh - ${appBarHeight}px)`
  //   }}},
  //   {slide: {direction: 'right', in: showTreesBg1}, img: {src: tree2Data.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     left: `${0 + widthOffset}vw`,
  //     height: `calc(${140 + heightOffset}vh - ${appBarHeight}px)`,
  //   }}},
  //   {slide: {direction: 'left', in: showTreesBg3}, img: {src: tree2FlipData.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     right: `${20 + parseInt(widthOffset*0.8)}vw`,
  //     opacity: 0.15,
  //     height: `calc(${100 + heightOffset}vh - ${appBarHeight}px)`
  //   }}},
  //   {slide: {direction: 'left', in: showTreesBg2}, img: {src: tree2FlipData.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     right: `${10 + parseInt(widthOffset*0.9)}vw`,
  //     opacity: 0.3,
  //     height: `calc(${120 + heightOffset}vh - ${appBarHeight}px)`
  //   }}},
  //   {slide: {direction: 'left', in: showTreesBg1}, img: {src: tree2FlipData.img, style: {
  //     bottom: heightOffset ? `-${heightOffset}vh`: 0,
  //     position: 'absolute',
  //     right: `${0 + widthOffset}vw`,
  //     height: `calc(${140 + heightOffset}vh - ${appBarHeight}px)`,
  //   }}},
  // ];

  return (
    <Box
      id='background-box'
      sx={newBackgroundSx}
    >
      {/* {!animationOff && imageList.map((data, index) =>
      <Slide
        key={index}
        direction={data.slide.direction}
        in={data.slide.in}
        easing={{
          enter: "cubic-bezier(0.4, 0, 1, 1)",
          exit: "linear"
        }}
      >
        <img alt="" src={data.img.src} style={data.img.style}/>
      </Slide> 
      )} */}
    </Box>
  );
}