import React, {Fragment} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { logoData } from '../assets';
import { color } from '../color';

const APP_BAR_HEIGHT = 64;

const appBarSx = {
  display: 'flex',
  width: '100%',
  background: `${color.primary}`,
  height: APP_BAR_HEIGHT,
  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
  boxShadow: 1,
};

const buttonStyle = {
  color: 'white', textShadow: '2px 2px #00000050',
  fontSize: 16,
  fontWeight: 600,
};

const logoStyle = {
  height: 50,
};

export default function CustomAppBar({buttonList, onClick, bottom}) {

  return (
    <Fragment>
      <Box
        id='appbar-box'
        bottom={bottom}
        sx={appBarSx}
      >
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          {buttonList.map((buttonData, index) =>
            <Grid item key={`button-${index}`}>
              {buttonData.type === "logo"
              ? (
              <Button onClick={buttonData.onClick}>
                <img alt="gabilud" src={logoData.img} style={logoStyle}/>
              </Button>
              )
              : (
              <Button onClick={buttonData.onClick} style={buttonStyle}>
                {buttonData.label}
              </Button>
              )
              }
            </Grid>
          )}
        </Grid>
      </Box>
    </Fragment>
  );
}
