import { createTheme } from '@mui/material/styles';
import {color} from './color';

const theme = createTheme({
  typography: {
    button: { // Here is where you can customise the button
      fontSize: 16,
      fontWeight: 550,
    },
    fontFamily: [
      'Raleway',
    ].join(','),
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: {
  //       body: {
  //         background: 'black',
  //      },
  //     },
  //   },
  // },
  palette: {
    // mode: 'dark',
    primary: {
      main: color.primary,
    },
    background: {
      default: '#FFFBFF'
    }
  },
});

export default theme;