import React, {useState, useRef, useEffect, forwardRef} from 'react';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Carousel from 'react-material-ui-carousel'
import Fade from '@mui/material/Fade';
import { styled, makeStyles } from '@mui/styles';
import texts from './texts';
import GetWindowDimensions from '../../utils/Hooks/GetWindowDimensions';
import Background from '../../components/Background';
import DjAvatar from '../../components/DjAvatar';

import GetScrollPosition from '../../utils/Hooks/GetScrollPosition';
import AppBar from '../../components/AppBar';
import PixModal, {PixGabrielButton, PixLudmylaButton} from '../../components/PixModal';

import {
  gabiludImage2Data,
  passargada1Data,
  passargada2Data,
  passargada3Data,
  passargada4Data,
  passargada5Data,
  djList,
  // logoData
} from '../../assets';

const mainBoxSx = {
  position: 'relative',
  // overflowX: 'hidden',
};

const appBoxSx = {
  display: 'flex',
  position: 'absolute',
  flexDirection: 'column',
  alignItems: 'center',
  // overflowY: 'scroll',
  overflowX: 'hidden',
  // height: 'calc(100vh - 64px)',
  width: '100%',
  zIndex: 10,
};

const mainBoxTypographySx = {
  position: 'absolute',
  top: 55,
  width: '100%'
  // color: 'white',
  // color: color.primary,
  // color: 'white',
  // textShadow: `1px 1px 3px black`,
};

const DEFAULT_SHADOW = "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)";

const StyledBox = styled(Box)({
  minHeight: "80vh",
  color: "black",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const observerOptions = {
  rootMargin: '0px 0px 0px 0px',
  threshold: 0.5 // half of item height
}

const ContentBox = forwardRef((props, ref) => {

  const [isVisible, setVisible] = React.useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {setVisible(entry.isIntersecting)});
    }, observerOptions);
    observer.observe(ref.current);
    // return () => observer.unobserve(boxRef.current);
    // eslint-disable-next-line
  }, []);

  return (
    <Fade in={isVisible} timeout={500}>
      <StyledBox ref={ref} {...props} />
    </Fade>
  )
});

const useStyles = makeStyles({
  carousel: {
    // height : "380px",
    width : ({width}) => width,
    minHeight: ({width}) => width - 60,
  },
  carouselImage: {
    width: ({width}) => width,
    borderRadius: '4px', boxShadow: DEFAULT_SHADOW    
  }
});

function getBackgroundOffset(width) {
  return Math.min(parseInt( -(1200 - width) / 14), 0);
};

export default function Home() {

  const [nightScale, setNightScale] = useState(0);
  const [pixModalData, setPixModalData] = useState(null);
  const pixModalOpen = Boolean(pixModalData);
  const onPixModalClose = () => setPixModalData(null);

  const scrollPosition = GetScrollPosition();

  const {
    width: windowWidth,
    height: windowHeight
  } = GetWindowDimensions();
  const mainBoxRef = useRef();
  const homeBoxRef = useRef();
  const welcomeBoxRef = useRef();
  const scenarioBoxRef = useRef();
  const howToGoBoxRef = useRef();
  const confirmPresenceRef = useRef();
  const oQueVestirRef = useRef();
  const presentsRef = useRef();
  const thePartyRef = useRef();

  const pageHeight = mainBoxRef?.current?.clientHeight ?? 0;

  const backgroundOffset = getBackgroundOffset(windowWidth);
  const contentBoxWidth = windowWidth - 50;

  const classes = useStyles({width: contentBoxWidth});

  useEffect(() => {
    let scrollTotalHeight = pageHeight - windowHeight;
    let scrollPercentage = parseFloat((Math.max(0, Math.min(1, scrollPosition / scrollTotalHeight))).toFixed(2));
    let startNightEffectAt = 0.9;
    let nightScale = 0;
    if (scrollPercentage > startNightEffectAt) {
      nightScale = (scrollPercentage - startNightEffectAt) * (1/(1 - startNightEffectAt));
    };
    setNightScale(isNaN(nightScale) ? 0 : nightScale);
  }, [scrollPosition, windowHeight, pageHeight] );

  const passargadaCarousol = [
    {src: passargada1Data.img, style: {width: contentBoxWidth, height: 'auto', borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada2Data.img, style: {width: contentBoxWidth, height: 'auto', borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada3Data.img, style: {width: contentBoxWidth, height: 'auto', borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada4Data.img, style: {width: contentBoxWidth, height: 'auto', borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
    {src: passargada5Data.img, style: {width: contentBoxWidth, height: 'auto', borderRadius: '4px', boxShadow: DEFAULT_SHADOW}},
  ];

  const scrollToPosition = (top) => {
    window.scrollTo({left: 0, top, behavior: 'smooth'});
  };

  // const scrollToElement = (element) => {
  //   let position = element.getBoundingClientRect();
  //   scrollToPosition(position.top + scrollPosition);
  // };

  const appbarButtonList = [
    {type: "logo",   onClick: () => scrollToPosition(0)},
    // {type: "button", label: 'B', onClick: () => scrollToElement(welcomeBoxRef.current)},
    // {type: "button", label: 'C', onClick: () => scrollToElement(scenarioBoxRef.current)},
    // {type: "button", label: 'C', onClick: () => scrollToElement(howToGoBoxRef.current)},
    // {type: "button", label: 'C', onClick: () => scrollToElement(confirmPresenceRef.current)},
    // {type: "button", label: 'P', onClick: () => scrollToElement(presentsRef.current)},
    // {type: "button", label: 'V', onClick: () => scrollToElement(oQueVestirRef.current)},
    // {type: "button", label: 'A', onClick: () => scrollToElement(thePartyRef.current)},
  ];

  const onClickPixButton = (data) => setPixModalData(data);

  return (
    <Box id="main-box" sx={mainBoxSx}>
      <AppBar bottom={0} buttonList={appbarButtonList}/>

      <Box ref={mainBoxRef} id="main-box" sx={appBoxSx}>

        <ContentBox width={"100vw"} id="home" ref={homeBoxRef}>
          <div style={{
            width: "100vw",
            overflow: 'hidden',
            position: 'relative',
          }}>
            <img alt="" src={gabiludImage2Data.img} style={{
              height: "calc(100vh - 60px)",
              width: "100vw",
              transform: 'translate(7%, 15%) scale(1.3)',
              objectPosition: '50% 0%',
              objectFit: 'cover',
            }}/>
            <Box sx={mainBoxTypographySx}>
              <Typography variant="h4" align='center' marginBottom={1}>
                Gabriel e Ludmyla
              </Typography>
              <Typography variant="h5" align='center' marginBottom={5}>
                28.10.2023
              </Typography>
            </Box>
          </div>
        </ContentBox>

        <ContentBox width={contentBoxWidth} ref={welcomeBoxRef}>
          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.bemVindo.title()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={1}>
            {texts.bemVindo.text1()}
          </Typography>
          <Typography align='justify' variant="body1">
            {texts.bemVindo.text2()}
          </Typography>
        </ContentBox>

        <ContentBox width={contentBoxWidth} ref={scenarioBoxRef}>
          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.cenario.title()}
          </Typography>
          <Box display="flex" width="100%" flexDirection="column">
            <Typography align='justify' variant="body1" marginBottom={1}>
              {texts.cenario.text1()}
            </Typography>
            <Carousel autoPlay className={classes.carousel}>
              {passargadaCarousol.map((imageData, index) => 
                <img key={index} alt="" src={imageData.src} className={classes.carouselImage}/>
              )}
            </Carousel>
          </Box>
        </ContentBox>

        <ContentBox width={contentBoxWidth} ref={howToGoBoxRef}>
          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.comoChegar.title()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.comoChegar.text1()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.comoChegar.text2()}
          </Typography>
          <iframe
            width={contentBoxWidth}
            height={contentBoxWidth*0.57}
            src={"https://www.youtube.com/embed/LFTwNbx3ah4?fs=0"}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture"
            title="Embedded youtube"
          />
        </ContentBox>

        <ContentBox width={contentBoxWidth} ref={confirmPresenceRef}>
          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.confirmarPresenca.title()}
          </Typography>
          <Typography align='justify' variant="body1">
            {texts.confirmarPresenca.text1()}
          </Typography>
        </ContentBox>

        <ContentBox width={contentBoxWidth} ref={presentsRef}>
          <Typography variant="h5" align='center' marginBottom={4}>
            {texts.presentes.title()}
          </Typography>
          <Typography align='justify' variant="body1">
            {texts.presentes.text1()}
          </Typography>
          <Box display="flex" width="100%" justifyContent={"center"} flexDirection={"column"} marginTop={4} marginBottom={4} gap={2}>
            <Button
              variant="contained"
              rel="noopener noreferrer" target="_blank" href="https://sites.icasei.com.br/gabilud/home"
            >
              LISTA DE PRESENTES
            </Button>
            <PixGabrielButton onClick={onClickPixButton} />
            <PixLudmylaButton onClick={onClickPixButton} />
          </Box>
          <Typography align='justify' variant="body1">
            {texts.presentes.text2()}
          </Typography>
        </ContentBox>

        <ContentBox height="150vh" width={contentBoxWidth} ref={oQueVestirRef}>
          <Typography variant="h5" align='center' marginBottom={5}>
            {texts.oQueVestir.title()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.oQueVestir.text1()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.oQueVestir.text2()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.oQueVestir.text3()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.oQueVestir.text4()}
          </Typography>
          <Typography align='justify' variant="body1" marginBottom={2}>
            {texts.oQueVestir.text5()}
          </Typography>
          <Typography align='justify' variant="body1">
            {texts.oQueVestir.text6()}
          </Typography>
        </ContentBox>

        <ContentBox height="100vh" width={contentBoxWidth} ref={thePartyRef}>
          <Typography variant="h5" align='center' marginBottom={7} color="white">
            {texts.aFesta.title()}
          </Typography>

          <Box display={"flex"} flexDirection="column" justifyContent={"center"} flexWrap={"wrap"} gap={3}>
            {djList.map((djData, index) => (
              <DjAvatar key={index} img={djData.img} name={djData.name} link={djData.link} size={100}/>
            ))}
          </Box>

        </ContentBox>
      </Box>

      <Background
        nightScale={nightScale}
        heightOffset={15}
        widthOffset={backgroundOffset}
        nightScaleThresholdList={[0.88, 0.91, 0.94]}
        animationOff={true}
      />
      <PixModal open={pixModalOpen} data={pixModalData} handleClose={onPixModalClose}/>
    </Box>
  );
};