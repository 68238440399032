import React from 'react' 


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const mainBoxSx = {
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  textAlign: 'center',
  alignItems: 'center',
  color: 'white'
};

export default function DjAvatar({img, name, link, size=150}) {

  const onClick = () => {
    window.open(link, '_blank');
  }

  return (
    <Box onClick={onClick} sx={mainBoxSx}>
      <Avatar alt="Younique" src={img} sx={{marginBottom: 2, width: size, height: size}}/>
      {name}
    </Box>
  );
};