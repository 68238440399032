import { Outlet } from 'react-router-dom';
import Home from './pages/Home';


function NotFound() {
  return (
    <>
      404: Not Found
    </>
  )
};

const routes = () => [
  {
    path: '/',
    element: <Outlet />,
    children: [
      { path: '/', element: <Home /> },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;