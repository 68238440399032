import {Fragment} from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const texts = {
  bemVindo: {
    title: () => "Vamos nos casar!",
    // text1: () => "Olá, pessoal, sejam bem-vindos ao site do nosso casamento! Aqui vocês vão encontrar todas as informações que precisam pra se preparar pro nosso dia especial. Contamos com a presença de vocês!",
    text1: () => "Bem-vindos e bem-vindas ao site do nosso casamento! Aqui vocês vão encontrar todas as informações que precisam para o nosso dia especial. Contamos com a presença de vocês!",
    text2: () => "Estamos construindo um casamento do nosso jeito, com nossa cara. Pra isso, pensamos em misturar duas ideias: um festival de trance e uma floresta mágica, unindo realidade com sonho, fantasia, aventura, tesouros, gnomos, fadas e os encantos de uma tardezinha.",
  },
  cenario: {
    title: () => "O Cenário",
    text1: () => (
      <Fragment>
        Como cenário, escolhemos o <Link rel="noopener noreferrer" target="_blank" color='inherit' href="https://www.instagram.com/sitiopassargada/">Sítio Passárgada</Link>, que nos lembra poesia, um refúgio para o encontro com a natureza e o acolhimento de todos que amamos.
        Sem limitação de horários e sem muitos protocolos, vamos curtir juntos até o sol raiar!
      </Fragment>
    )
  },
  comoChegar: {
    title: () => "Como Chegar",
    text1: () => "A entrada do casamento será pelo estacionamento do Sítio Passárgada (estacionamento gratuito disponível para os convidados). Veja abaixo como chegar ao estacionamento do Sítio (saindo de Salvador em direção a Guarajuba pela Estrada do Côco):",
    text2: () => (
        <Link
          rel="noopener noreferrer" target="_blank" color='inherit' href="https://goo.gl/maps/4RZ82g7j7KQGCWNZ7"
        >
          <Box display="flex">
            <Box mr={1} marginTop={0.3}>
              <LocationOnIcon />
            </Box>
            R. Beco da Lapinha - Monte Gordo, Camaçari - BA
          </Box>
        </Link>
    )
  },
  confirmarPresenca: {
    title: () => "Confirmar Presença",
    text1: () => <>Confirme sua presença até o dia 15/10/2023 clicando <Link rel="noopener noreferrer" target="_blank" color='inherit' href="http://form.gabilud.com" >aqui</Link></>,
  },
  oQueVestir: {
    title: () => "O que vestir",
    text1: () => "Como direcionamento inicial, sugerimos o estilo casual. Será um casamento na primavera, ao ar livre, numa cidade de sol e mar, então recomendamos que escolha roupas que possibilitem curtir o momento sem calor ou desconfortos. Quem quiser entrar na vibe do tema, tá liberado usar acessórios que remetam às fadas, elfos, gnomos, borboletas... Não é uma festa à fantasia, mas haverá elementos temáticos no evento :)",
    text2: () => <> <b><u>Não usar</u></b> <br/>Terno, gravata, sapato social, paetês, vestidos longos pesados, sandália havaiana, jeans, short. Recomendamos evitar saltos finos, pois o casamento será num sítio (área verde).</>,
    text3: () => <b>O importante é usar roupas leves, confortáveis, tons claros (estampas estão liberadas!) e acessórios que remetam à magia que estamos tentando promover!</b>,
    text4: () => <><b>Sugestões para homens:</b> look informal, calça de sarja ou linho, calça com barra dobrada, camisa, sandália de couro, mocassim, camisa de cores claras. </>,
    text5: () => <><b>Sugestões para mulheres:</b> look informal, sandália rasteira, vestidos soltos midi ou longo, conjuntos de saia e cropped, macacão, calça e blusa leves.</>,
    text6: () => <>Clique <Link rel="noopener noreferrer" target="_blank" color='inherit' href="https://1drv.ms/f/s!Ap8PWIgjsFUqg8M-6dhGW-QZh0-AyQ?e=CN8hNH" >aqui</Link> para ver algumas referências que separamos para ajudar vocês! </>
  },
  presentes: {
    title: () => "Presentes",
    text1: () => "Fizemos uma lista de presentes no icasei. Caso queira nos presentear (para além da sua presença, que já será um presentão!), pode acessar as informações abaixo. Os pagamentos pelo icasei podem ser feitos via boleto ou cartão de crédito (inclusive parcelado). Se preferir fazer um pix para um de nós, os QR Codes pra nossas contas também seguem abaixo.",
    text2: () => "Agradecemos muito seu presente ❤️"
  },
  aFesta: {
    title: () => "Vai ter festa na floresta!",
    text1: () => "Line Up será liberada em breve",
  },
};

Object.freeze(texts);
export default texts;